import React from 'react';
import logo from './butchbuster.svg'
import './App.css';

function App() {
  return (
    <div className="App-header" >
      <a className='logo-link' href='https://app.plex.tv/'>
        <img className='logo' src={logo} alt='butchbuster logo'>
        </img>
      </a>
    </div >
  );
}

export default App;
